import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import {
    editCities
} from "../actions/citiesactions";

export default function Cities() {
    const columns = [
        { title: languageJson.city_name, field: 'city_name', },
        { title: languageJson.rate_per_km, field: 'rate_per_kilometer', type: 'numeric' },
        { title: languageJson.rate_per_hour, field: 'rate_per_hour', type: 'numeric' },
        { title: languageJson.min_fare, field: 'min_fare', type: 'numeric' },
        { title: languageJson.company_commission, field: 'convenience_fees', type: 'numeric' }
    ];
    const [data, setData] = useState([]);
    const cities = useSelector(state => state.cities);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cities.cars) {
            setData(cities.cars);
        }
    }, [cities.cars]);

    const removeExtraKeys = (tblData) => {
        if (tblData.city_lat) tblData.city_lat = parseFloat(tblData.city_lat);
        if (tblData.city_lon) tblData.city_lon = parseFloat(tblData.city_lon);
        return tblData;
    }

    return (
        cities.loading ? <CircularLoading /> :
            <div>
                <MaterialTable
                    title={languageJson.Cities}
                    columns={columns}
                    data={data}
                    options={{
                        exportButton: true
                    }}
                    editable={{

                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.push(newData);
                                    dispatch(editCities(removeExtraKeys(tblData), "Add"));
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData[tblData.indexOf(oldData)] = newData;
                                    dispatch(editCities(removeExtraKeys(tblData), "Update"));
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const tblData = data;
                                    tblData.splice(tblData.indexOf(oldData), 1);
                                    dispatch(editCities(removeExtraKeys(tblData), "Delete"));
                                }, 600);
                            }),
                    }}
                />
                {/* <h3>You can use services like: <a target='_blank' href='https://www.mapcoordinates.net/en'>https://www.mapcoordinates.net/en</a></h3> */}
            </div>
    );
}

