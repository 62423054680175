import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import DailyEarningDialog from "./DailyEarningDialog";

export default function Earningreports() {
  const bookingdata = useSelector((state) => state.bookingdata);
  const [allBookings, setAllBookings] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const columns = [
    { title: languageJson.year, field: "year", filtering: false },
    {
      title: languageJson.months,
      field: "monthsName",
      lookup: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
    },
    {
      title: languageJson.trip_cost_driver_share,
      field: "rideCost",
      filtering: false,
    },
    {
      title: languageJson.convenience_fee,
      field: "convenienceFee",
      filtering: false,
    },
    {
      title: languageJson.Gross_trip_cost,
      field: "tripCost",
      filtering: false,
    },
    {
      title: languageJson.Discounts,
      field: "discountAmount",
      filtering: false,
    },
    {
      title: languageJson.Customer_paid,
      field: "customerPaid",
      filtering: false,
    },
    { title: languageJson.Profit, field: "myEarning", filtering: false },
  ];

  const [data, setData] = useState([]);
  const Earningreportsdata = useSelector((state) => state.Earningreportsdata);

  useEffect(() => {
    if (bookingdata.bookings) {
      setAllBookings(bookingdata.bookings);
    }
  }, [bookingdata.bookings]);

  useEffect(() => {
    if (Earningreportsdata.Earningreportss) {
      setData(Earningreportsdata.Earningreportss);
    }
  }, [Earningreportsdata.Earningreportss]);

  const handleOpenDialog = (row) => {
    setSelectedMonth(row);
    setOpenDialog(true);
  };

  return Earningreportsdata.loading ? (
    <CircularLoading />
  ) : (
    <>
      <MaterialTable
        title={languageJson.earning_reports}
        columns={columns}
        data={data}
        onRowClick={(evt, selectedRow) => handleOpenDialog(selectedRow)}
        options={{
          exportButton: true,
          // filtering: true,
        }}
      />
      <DailyEarningDialog
        open={openDialog}
        setOpen={setOpenDialog}
        bookingData={allBookings}
        selectedMonth={selectedMonth}
      />
    </>
  );
}
