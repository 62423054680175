import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DailyEarningTable from "./../components/DailyEarningTable";
import languageJson from "../config/language";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DailyEarningDialog({
  open,
  setOpen,
  bookingData,
  selectedMonth,
}) {
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (selectedMonth !== null) {
      let tempArr = bookingData.filter((item) => {
        let customDate = new Date(item.tripdate);
        let selectedMonthFormat = new Date(selectedMonth.dated);
        let month = customDate.getMonth() + 1;
        let month2 = selectedMonthFormat.getMonth() + 1;
        if (month == month2) return item;
      });

      setFilterData(tempArr);
    }
  }, [selectedMonth]);

  const handleClose = () => {
    setOpen(false);
    setFilterData([]);
  };

  const formatDate = (val) => {
    let dateeeeee = new Date(val);
    let day = dateeeeee.getDate();
    let month = dateeeeee.getMonth() + 1;
    let year = dateeeeee.getFullYear();
    let hourss = dateeeeee.getHours();
    let minutess = dateeeeee.getMinutes();
    let secondss = dateeeeee.getSeconds();
    return `${day}-${month}-${year} ${hourss}:${minutess}:${secondss}`;
  };

  const columns = [
    {
      title: "Date/Time",
      field: "tripdate",
      render: (rowData) => <p>{formatDate(rowData.tripdate)}</p>,
    },
    { title: languageJson.customer_name, field: "customer_name" },
    { title: languageJson.assign_driver, field: "driver_name" },
    { title: languageJson.driver_share, field: "driver_share" },
    { title: languageJson.company_commission, field: "convenience_fees" },
    { title: "Total Earning", field: "customer_paid" },
  ];

  console.log(filterData, "filterDatafilterData");

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="body"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          History Earning Report
        </DialogTitle>
        <DialogContent dividers>
          <DailyEarningTable columns={columns} tableData={filterData} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
