import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import DriverDailyEarningDialog from "./DriverDailyEarningDialog";

export default function DriverEarning() {
  const bookingdata = useSelector((state) => state.bookingdata);
  const [allBookings, setAllBookings] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    { title: languageJson.year, field: "year", filtering: false },
    {
      title: languageJson.months,
      field: "monthsName",
      lookup: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
    },
    { title: languageJson.driver_name, field: "driverName", filtering: false },
    {
      title: languageJson.vehicle_type,
      field: "driverVehicleNo",
      filtering: false,
    },
    {
      title: languageJson.earning_amount,
      field: "driverShare",
      filtering: false,
    },
  ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector((state) => state.driverearningdata);

  useEffect(() => {
    if (bookingdata.bookings) {
      setAllBookings(bookingdata.bookings);
    }
  }, [bookingdata.bookings]);

  useEffect(() => {
    if (driverearningdata.driverearnings) {
      setData(driverearningdata.driverearnings);
    }
  }, [driverearningdata.driverearnings]);

  const handleOpenDialog = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  return driverearningdata.loading ? (
    <CircularLoading />
  ) : (
    <>
      <MaterialTable
        title={languageJson.driver_earning}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          grouping: true,
          // filtering: true,
        }}
        onRowClick={(evt, selectedRow) => handleOpenDialog(selectedRow)}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                // tblData.splice(tblData.indexOf(oldData), 1);
                // dispatch(editCarType(removeExtraKeys(tblData), "Delete"));
              }, 600);
            }),
        }}
      />
      <DriverDailyEarningDialog
        open={openDialog}
        setOpen={setOpenDialog}
        bookingData={allBookings}
        selectedRow={selectedRow}
      />
    </>
  );
}
