import React from "react";
import MaterialTable from "material-table";

export default function DailyEarningTable({ columns, tableData }) {
  return (
    tableData.length > 0 && (
      <MaterialTable
        title={""}
        columns={columns}
        data={tableData}
        options={{
          exportButton: true,
        }}
      />
    )
  );
}
