export default {
  booking_date: "Booking Date And Time",
  trip_start_time: "Trip Begin",
  trip_end_time: "Trip End Time",
  customer_name: "Name Of Customer’s",
  car_type: "All Vehicles",
  vehicle_no: "Vehicle Number",
  pickup_address: "Pickup Location",
  drop_address: "Drop Location",
  assign_driver: "Drivers Name",
  booking_status: "Booking Status",
  trip_cost: "Trip cost",
  driver_share: "Driver Share",
  discount_ammount: "Discount Amount",
  payment_status: "Payment Status",
  payment_mode: "Mode of payment",
  booking_text: "Bookings",
  image: "Image",
  name: "Name",
  rate_per_km: "Rate Per Kilometer",
  rate_per_hour: "Rate Per Hour",
  min_fare: "Minimum Fare",
  convenience_fee: "Car to go commission",
  company_commission: "Company Commission",
  created_at: "Created At",
  joining_date: "Joining Date",
  convenience_fee_percent: "Convenience Fees (%)",
  today_text: "TODAY EARNING",
  gross_earning: "TOTAL EARNINGS",
  admin_convenience_fee: "Our Earning",
  this_month_text: "MONTHLY EARNING",
  total: "TOTAL EARNING",
  real_time_driver_section_text: "Drivers Realtime",
  allow_location: "Allow Location for the Realtime Map",
  sign_in: "Sign in",
  email_address: "Email Address",
  password: "password",
  sign_in_error: "Sign In Error. Please check Email and Password.",
  device_type: "Device Type",
  user_type: "User Type",
  title: "Title",
  body: "Body",
  push_notification_title: "Push Notifications",
  promo_name: "Promo Name",
  description: "Description",
  promo_type: "Promo Type",
  promo_discount_value: "Promo Discount value",
  city_name: "City Name",
  city_lat: "City Latitude ",
  city_lon: "City Longitude",
  max_limit: "Max Limit Value",
  min_limit: "Minimum Order Count",
  start_date: "Start Date (dd/mm/yyyy)",
  end_date: "End Date (dd/mm/yyyy)",
  promo_usage: "Promo Usage Limit",
  promo_used_by: "Promo Used By",
  promo_offer: "Promo and Offers",
  my_cities: "Cities",
  refferal_bonus: "Referral Bonus",
  submit: "Submit",
  update_failed: "Update Failed",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  mobile: "Mobile",
  profile_image: "Profile Image",
  vehicle_model: "Vehicle Model",
  account_approve: "Account Approved",
  driver_active: "Driver Active Status",
  Cnic_Front_image: "Cnic Front",
  Cnic_Back_image: "Cnic Back",
  lisence_image_front: "License Image Front",
  lisence_image_back: "License Image Back",
  Papers: "Bike/Car Papers",
  Selfie: "Selfie Image",
  vehicle_number: "Vehicle Number",
  wallet_balance: "Wallet Balance",
  pending_commition: "Pending Commition",
  signup_via_refferal: "Signup Via Referral",
  refferal_id: "Refferal Id",
  all_user: "All Users",
  notification_sent: " notifications sent.",
  notification_sent_failed: "Notification sending failed.",
  no_user_match: "No Users Matched",
  no_user_matching: "No users found matching your selection.",
  dashboard_text: "Dashboard",
  user: "Users",
  driver: "Drivers",
  booking_history: "Booking History",
  promo: "Promos",
  Cities: "Cities",
  logout: "Log Out",
  driver_earning: "Driver Earning History",
  earning_amount: "Earning Amount",
  driver_name: "Driver Name",
  driver_image: "Driver Image",
  months: "Months",
  year: "Years",
  vehicle_type: "Vehicle Number",
  lisence_no: "Lisence Number",
  booking_discount_earning: "Booking Discount",
  trip_cost_driver_share: "Driver earring",
  Gross_trip_cost: "Total trip cost",
  total_earning: "Total Earning",
  Discounts: "Discounts",
  Customer_paid: "Total rider payment",
  customer_payment: "Customer’s Payment",
  Profit: "Company Earning",
  earning_reports: "Earning Reports",
  cash_payment_amount: "Cash payment amount",
  card_payment_amount: "Card payment amount",
  wallet_payment_amount: "Wallet payment amount",
  payment_getway: "Payment Getway",
};
